/* .hero-btn {
    background-color: blueviolet;
} */
/* .hero-btn:hover {
    background-color: aqua;
} */

.hero-btn{
    padding: 18px 20px;
    border: none;
    font-size: 14px;
    color: #fff;
    background: var(--button-color);
    border-radius:  5px ; 

}

@media (max-width: 650px) {
    .hero-btn{
        padding: 12px 0px;
        border: none;
        font-size: 16px;
        color: #fff;
      
        width: 97%;
    margin: auto;
    border-radius: 4px;
    margin-top: 30px;
    }
}