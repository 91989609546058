

.container-patch {
    width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;
    /* height: 10rem;
    background-color: var(--background-color); */
    position: sticky;
    top: 0;
}
.wrapper {
    display: flex;
    width: 100%;
    /* justify-content: space-evenly; */
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 5px;
    /* margin: 10px; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    border-radius: 5px;
    /* height: 60px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}



