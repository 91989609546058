.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  border: none;
}

.auto-complete-input {
  width: 18rem;
  margin: 5px;
  padding: 2px;
}
.select-hotel-cont-div {
  width: 100%;
  margin: auto;
}
.select-auto-options {
  width: 90%;
  border: 1px solid red;
  /* margin: auto; */
}

@media (max-width: 650px) {

  .auto-complete-input {
    width: auto;
    margin: 20px 6px;
    padding: 0px;
    background-color: #fff;
    border-radius: 4px;
  }
}